function MobileControls() {
  return(
    <div class='mobile-control__wrapper hide-no-touch'>
      <div class='mobile-control__wheel'><div class='mobile-control__wheel-inner'></div></div>
      <div class='mobile-control flex-perfect-center' data-direction='up-left'></div>
      <div class='mobile-control flex-perfect-center' data-direction='up'><i class='fa fa-angle-up'></i></div>
      <div class='mobile-control flex-perfect-center' data-direction='up-right'></div>

      <div class='mobile-control flex-perfect-center' data-direction='left'><i class='fa fa-angle-left'></i></div>
      <div class='mobile-control flex-perfect-center' data-direction='right'><i class='fa fa-angle-right'></i></div>

      <div class='mobile-control flex-perfect-center' data-direction='down-left'></div>
      <div class='mobile-control flex-perfect-center' data-direction='down'><i class='fa fa-angle-down'></i></div>
      <div class='mobile-control flex-perfect-center' data-direction='down-right'></div>

    </div>
  )
}

export default MobileControls