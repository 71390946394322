import {useEffect, useState} from 'react'
import Header from "./components/Nav/Header";
import Welcome from "./components/Screens/Welcome";
import Message from "./components/Screens/Message"
import MobileControls from './components/Game/MobileControls';

function App() {
  const [ui, setUi] = useState('welcome')
  const [player, setPlayer] = useState()
  const [error, setError] = useState()
  const [message, setMessage] = useState(false)

  const [audioReady, setAudioReady] = useState(false)

  useEffect(() => {
    setupAudio()

    document.addEventListener('gameStateChange', e => {
      const gameState = e.detail;
      if (gameState === 'game-over') {
        setMessage('game-over')
      } else if (gameState === 'level-complete') {
        setMessage('level-complete')
      }
    })
  }, [])

  function setupAudio() {
    const patchbayScript = document.createElement('script');
    patchbayScript.type = 'module';
    patchbayScript.onload = function () {
      //console.log('IM script')
    };
    patchbayScript.src = "https://js.patchbay.co/v1/embed.js?key=63c1ba6714fc768c34f09475";
    document.head.appendChild(patchbayScript);

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(patchbayScript, firstScriptTag);

    document.addEventListener('audioOptOut', () => {
      setAudioReady(true);
    });
    document.addEventListener('audioReady', () => {
      setAudioReady(true);
    });
  }

  function choosePlayer(e) {
    const player = e.target.dataset.name
    setPlayer(player)
    document.dispatchEvent(new CustomEvent('chooseCharacter', {detail: player}))
  }

  function startGame() {
    if (player) {
      setUi('game')
      document.dispatchEvent(new CustomEvent('startGame', {detail: player}))
    } else {
      setError('Please choose a band member')
    }
  }

  function restartGame() {
    setUi('game')
    setMessage(null)
    document.dispatchEvent(new CustomEvent('restartGame'))
  }

  function nextLevel() {
    setUi('game')
    setMessage(null)
    document.dispatchEvent(new CustomEvent('nextLevel'))
  }

  let uiElement;
  if (ui === 'welcome') {
    uiElement = <Welcome
                  choosePlayer={choosePlayer}
                  startGame={startGame}
                  error={error}
                  player={player}
                  audioReady={audioReady}
                />
  }

  return (
    <div className="App">

      <div id='game'></div>
      <Message message={message} restartGame={restartGame} nextLevel={nextLevel} />
      {uiElement}


      <Header player={player} />
      {/* <MobileControls /> */}

      
        <MobileControls />

      <div class="patchbay-player" data-id="63c1bac614fc768c34f0b584"></div>
    </div>
  );
}

export default App;
