import classes from './Welcome.module.scss'

function PlayerSelect(props) {
  return (
    <div className='start-band-members'>	
      <p>
        Choose your band member:
      </p>
      {/* avatar.querySelector('img').src = '/game/sprites/band/'+character+'_active-100.png'; */}
      <div className={classes.characterSelect} data-player={props.player}>
        <div onClick={props.choosePlayer} className='avatar' data-role='avatar' data-name='brad'>
          <img src={`/game/sprites/band/brad_${props.player === 'brad' ? 'active-' : ''}100.png`} /><br/>
          BRAD
        </div>
        <div onClick={props.choosePlayer} className='avatar' data-role='avatar' data-name='alex'>
          <img src={`/game/sprites/band/alex_${props.player === 'alex' ? 'active-' : ''}100.png`} /><br/>
          ALEX
        </div>
        <div onClick={props.choosePlayer} className='avatar' data-role='avatar' data-name='collin'>
          <img src={`/game/sprites/band/collin_${props.player === 'collin' ? 'active-' : ''}100.png`} /><br/>
          COLIN
        </div>
        <div onClick={props.choosePlayer} className='avatar' data-role='avatar' data-name='kryz'>
          <img src={`/game/sprites/band/kryz_${props.player === 'kryz' ? 'active-' : ''}100.png`} /><br/>
          KRYZ
        </div>
        <div onClick={props.choosePlayer} className='avatar' data-role='avatar' data-name='steven'>
          <img src={`/game/sprites/band/steven_${props.player === 'steven' ? 'active-' : ''}100.png`} /><br/>
          STEPHAN
        </div>
      </div>

      <button onClick={props.startGame} className='btn btn-pill btn-primary'>START GAME</button>
      <br/>
      <span id='start-error'>{props.error}</span>
    </div>
  )
}

export default PlayerSelect