import { Fragment } from "react"

function Message(props) {
  return(
    <Fragment>
      <div className='screen flex-perfect-center' id='game-over' data-active={props.message === 'game-over'}>

        <div className='screen__content text-center'>
          <div className='social-cta'>	
            <a className='btn-twitter btn btn-pill social-share' href="https://twitter.com/intent/tweet?text=Try and beat my high on Third Eye Blind Pacman!&url=https://www.thirdeyeblindscreamer.com/&via=ThirdEyeBlind"><i className='fab fa-twitter'></i> SHARE</a> 
            <a className='facebook-share btn btn-pill social-share btn-facebook' data-url="https://www.thirdeyeblindscreamer.com"><i className='fab fa-facebook-f'></i> SHARE</a>
          </div>
          <div id='game-over-title' className='title'>
            GAME OVER!
          </div>

          <div className='title-sm'>
            <span id='level-count'></span>
            SCORE: <span id='game-over-score'></span>
          </div>

          <button id='play-again' onClick={props.restartGame} className='btn btn-pill btn-primary'>Play Again</button>
          {/* <button id='add-high-score' className='btn btn-pill btn-primary'>Add High Score</button>
          <button id='open-leaderboard' className='btn btn-pill btn-primary'>Leaderboard</button> */}
        </div>

      </div>

      <div class='screen flex-perfect-center' id='level-complete' data-active={props.message === 'level-complete'}>

        <div class='screen__content text-center'>	
          <div class='title'>
            LEVEL <span id='level-count'></span> COMPLETE!
          </div>
      
          <div class='title-sm'>
            SCORE: <span id='level-score'></span>
          </div>
      
          <button id='start-next-level' onClick={props.nextLevel} class='btn btn-pill btn-primary'>Start Next Level</button>
        </div>
      
      </div>
    </Fragment>
  )
}

export default Message