function Header(props) {
  return(
    <div className='game-stats'>
      <div className=''>
        <strong>SCORE:</strong> <span id='score-display'>0</span><br/>
        <strong>LEVEL:</strong> <span id='level-display'>1</span>
      </div>
      <div className='game-stats__health flex-between'>
        <img src={`/game/sprites/${props.player}-head.png`} className='health-icon' id='health-3' />
        <img src={`/game/sprites/${props.player}-head.png`} className='health-icon' id='health-2' />
        <img src={`/game/sprites/${props.player}-head.png`} className='health-icon' id='health-1' />
      </div>
    </div>
  )
}

export default Header