import PlayerSelect from './PlayerSelect'
import classes from './Welcome.module.scss'

function Welcome(props) {
  return(
    <div className='screen active flex-perfect-center' id='start-screen' data-active='true'>

      <div className='screen__content text-center'>	
        
        <img src='/images/penant-title_crop.png' className='start-title'/>

        {props.audioReady
          ?
          <PlayerSelect player={props.player} startGame={props.startGame} choosePlayer={props.choosePlayer}/>
          :
          <div class="patchbay-patch" data-id="63c1bac614fc768c34f0b584">Loading...</div>
        }
      </div>

    </div>
  )
}

export default Welcome